<template>
    <div id="manage-space-page">
        <toolbar-back-button-component :title="$t('manage_space_page.space_information')"/>
        <div class="pa-4">
            <p class="font-weight-bold mt-2">{{ $t('manage_space_page.space_information') }}</p>

            <div id="company-name" class="mb-2">
                <div class="white-text-field">
                    <v-text-field v-model="space.company_name" :loading="loading" filled hide-details
                                  :label="$t('manage_space_page.company_name')"
                                  @click="openDialogEdition($t('manage_space_page.modify_company_name'), $t('manage_space_page.company_name'),
                                  space.company_name, 'company_name')"
                                  :disabled="!userHasAccess('BASE', role_admin)"
                                  data-cy="company-name"
                    >
                        <template v-slot:append>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-text-field>
                </div>
            </div>

            <div id="client-name-space" class="mb-2">
                <div class="white-text-field">
                    <v-text-field v-model="space.client_space_name" :loading="loading" filled hide-details
                                  :label="$t('manage_space_page.client_space_name')" readonly
                                  @click="openDialogEdition($t('manage_space_page.modify_client_space_name'), $t('manage_space_page.client_space_name'),
                                  space.client_space_name, 'client_space_name')"
                                  :disabled="!userHasAccess('BASE', role_admin)"
                                  data-cy="client-space-name"
                    >
                        <template v-slot:append>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-text-field>
                </div>
            </div>

            <div id="siret" class="mb-2">
                <div class="white-text-field">
                    <v-text-field v-model="space.siret" :loading="loading" filled hide-details
                                  :label="$t('manage_space_page.siret_siren')" readonly
                                  @click="openDialogEdition($t('manage_space_page.modify_siret_siren'), $t('manage_space_page.siret_siren'),
                                  space.siret, 'siret')"
                                  :disabled="!userHasAccess('BASE', role_admin)"
                                  data-cy="siret-siren"
                    >
                        <template v-slot:append>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-text-field>
                </div>
            </div>

            <div id="client-code" class="mb-2">
                <div class="white-text-field">
                    <v-text-field v-model="space.code_client" :loading="loading" filled hide-details
                                  :label="$t('manage_space_page.code_client')" readonly
                                  @click="openDialogEdition($t('manage_space_page.modify_code_client'), $t('manage_space_page.code_client'),
                                  space.code_client, 'code_client')"
                                  :disabled="!userHasAccess('BASE', role_admin)"
                                  data-cy="code-client"
                    >
                        <template v-slot:append>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-text-field>
                </div>
            </div>

            <v-dialog v-model="dialog_edition" max-width="500px" v-if="userHasAccess('BASE', role_admin)">
                <v-card data-cy="dialog-edition">
                    <v-card-title class="mb-0">
                        <p class="font-weight-bold font-18 text-uppercase">{{ title_dialog_edition }}</p>
                    </v-card-title>

                    <v-form ref="form_space">
                        <v-card-text class="py-0">
                            <v-text-field v-model="property_value_to_edit" :label="label_input_edition"
                                          :rules="[rules.required]" filled
                                          data-cy="edit-space-input"
                            />
                        </v-card-text>
                        <v-card-actions class="justify-end pb-4">
                            <v-btn color="accent" text @click="closeDialogEdition()" data-cy="cancel-btn">
                                <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                            </v-btn>
                            <v-btn :disabled="!valid" :loading="loading_edit" @click="updateSpace()"
                                   class="btn-secondary-loader" color="secondary" text
                                   data-cy="submit-btn"
                            >
                                <span
                                    :class="valid ? 'secondary--text font-weight-bold' : 'font-color-light font-weight-bold'"
                                >
                                    {{ $t('global.validate') }}
                                </span>
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import ClientRepository from "../../repositories/ClientRepository";
import ToolbarBackButtonComponent from "../../components/Global/ToolbarBackButtonComponent";

export default {
    name: 'ManageSpace',
    components: {ToolbarBackButtonComponent},
    created() {
        this.getClient();
    },
    data() {
        return {
            space: {
                company_name: null,
                client_space_name: null,
                siret: null,
                code_client: null,
            },

            rules: {
                required: value => !!value || this.$t('global.field_required'),
            },

            dialog_edition: false,
            property_value_to_edit: null,
            property_name: null,
            title_dialog_edition: '',
            label_input_edition: '',

            loading: false,
            loading_edit: false,
        }
    },
    computed: {
        valid() {
            let valid = false;
            if (this.property_value_to_edit !== null && this.property_value_to_edit !== "") {
                valid = true;
            }
            return valid
        }
    },
    methods: {
        openDialogEdition(titleDialogEdition, labelInputEdition, propertyValueToEdit, propertyName) {
            if (!this.loading && this.userHasAccess('BASE', this.role_admin)) {
                this.title_dialog_edition = titleDialogEdition;
                this.label_input_edition = labelInputEdition;
                this.property_value_to_edit = propertyValueToEdit;
                this.property_name = propertyName;
                this.dialog_edition = true;
            }
        },

        getClient() {
            this.loading = true;
            ClientRepository.getClientById(this.$store.getters["user_connected/user_connected"].id_client).then((success) => {
                this.space.company_name = success.data.data.company_name;
                this.space.client_space_name = success.data.data.client_space_name;
                this.space.siret = success.data.data.siret;
                this.space.code_client = success.data.data.code_client;
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading = false;
            });
        },

        updateSpace() {
            if (this.$refs.form_space.validate()) {
                this.loading_edit = true;
                let oldPropertyValue = this.space[this.property_name];
                this.space[this.property_name] = this.property_value_to_edit;

                ClientRepository.updateClient(
                    this.$store.getters["user_connected/user_connected"].id_client,
                    this.space
                ).then(() => {
                    this.showSnackbar('success', this.$t('manage_space_page.space_updated'));
                }).catch((error) => {
                    this.space[this.property_name] = oldPropertyValue;
                    this.manageError(error);
                }).finally(() => {
                    this.closeDialogEdition();
                    this.getClient();
                    this.$store.dispatch('user_connected/getAuthenticateUser');
                    this.loading_edit = false;
                });
            }
        },

        closeDialogEdition() {
            this.title_dialog_edition = '';
            this.label_input_edition = '';
            this.property_value_to_edit = '';
            this.property_name = '';
            this.dialog_edition = false;
            this.$refs.form_space.reset();
        }
    }
}
</script>